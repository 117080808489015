













































































import {
  defineComponent, computed, reactive, watchEffect,
} from '@vue/composition-api';
import forEach from 'lodash/forEach';
import pickBy from 'lodash/pickBy';
import { formatData } from '../utils';
import { $DYNAMIC_REPORT_CONFIGS } from '../composables/config';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const apiData = props.data;
    const configs = computed(
      () => $DYNAMIC_REPORT_CONFIGS.value.find(item => item.name === 'income_statement'),
    );

    const statementData = apiData.income_statement;
    const dataSet = {};
    const reverseSpectrum = [
      'cost_of_sales',
      'administrative_expense',
      'total_expense',
      'interest_expense',
      'income_tax',
    ];
    const IndentedLabel = [
      'income',
    ];

    const seriesKeys = reactive([]);
    const fieldKeys = reactive([]);

    watchEffect(
      () => {
        const rawSeriesKeys = configs.value.series.filter(item => item.display).map(item => item.name);
        const anualReportKeys = Object.keys(statementData).filter(item => item.match(/^\d+$/));
        rawSeriesKeys.splice(rawSeriesKeys.indexOf('anual_report'), 1, ...anualReportKeys);
        seriesKeys.splice(0, seriesKeys.length, ...rawSeriesKeys);
        fieldKeys.splice(0, fieldKeys.length, ...configs.value.fields.filter(item => item.display).map(item => item.name));

        seriesKeys.forEach((series, seriesIndex) => {
          const formattedData = {};
          fieldKeys.forEach((field, fieldIndex) => {
            const format = series === 'percentile' ? null : '0,0.00';

            Object.assign(formattedData, {
              [field]: formatData(statementData[series][field], format),
            });
          });

          Object.assign(dataSet, {
            [series]: formattedData,
          });
        });
      },
    );

    const fieldConfigs = (fieldName: string) => configs.value.fields.find(item => item.name === fieldName);

    return {
      title: 'งบกำไรขาดทุน (Income Statement)',
      dataSet,
      reverseSpectrum,
      IndentedLabel,
      configs,
      seriesKeys,
      fieldKeys,
      fieldConfigs,
    };
  },
});
